import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_3/sub_4/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    leftColumn: mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "slide-3-4-1"}}) {
        body
      }
    data: mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "map-game"}}) {
      frontmatter {
        resultsHeader
        legendFirstLabel
        legendSecondLabel
        language
        winnerMessage
      }
      exports {
        countries {
          country
          message
        }
        winners
        losers
        draw
      }
    }
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
